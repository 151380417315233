import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import opinionImage1 from "../../../images/opinion-1.jpg"
import opinionImage2 from "../../../images/opinion-2.jpg"
import opinionImage3 from "../../../images/opinion-3.jpg"
import opinionImage4 from "../../../images/opinion-4.jpg"
import PlayVideo from "../../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import "./OpinionList.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import NoImage from "../../../images/no-image.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const opinionData = [
  {
    id: 1,
    image: opinionImage1,
    videoId: "V8ksSGxo6no",
    name: "Residential",
    description: "Where would you buy your ideal starter home in South Dublin",
  },
  {
    id: 2,
    image: opinionImage2,
    videoId: "",
    name: "Auctions",
    description: "DNG Market Review South West Region 2019 & Outlook 2020",
  },
  {
    id: 3,
    image: opinionImage3,
    videoId: "",
    name: "Residential",
    description: "Where would your ideal starter home be in West Dublin",
  },
  {
    id: 4,
    image: opinionImage4,
    videoId: "V8ksSGxo6no",
    name: "DNG",
    description:
      "Introducing our new Head of Lettings Management Andrew Symonds",
  },
]

const OpinionList = () => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        popularNew(publicationState: LIVE) {
          blogs {
            id
            imagetransforms
            Title
            URL
            Video_Url
            Tail_Image {
              alternativeText
              url
            }
            blog_categories {
              Category_Name
            }
          }
        }
      }
    }
  `)
  const [opinionList, setOpinionList] = useState(
    data?.glstrapi?.popularNew?.blogs
  )
  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const ImageRender = ({
    alt,
    image_url,
    node,
    processedImages,
    imagesize,
  }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="blog.Tail_Image.smalltail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }


  return (
    <Fragment>
      {opinionList.length>0&&
      <div className="opinion-block">
        <h2 className="d-md-none d-lg-block">popular insight</h2>
        <h2 className="d-none d-md-block d-lg-none">insight & opinion</h2>
        <ul className="opinion-list">
          {opinionList?.map((item, i) => {
            let processedImages = JSON.stringify({})
            if (item?.imagetransforms?.Image_Transforms) {
              processedImages = item.imagetransforms.Image_Transforms
            }
            return (
              <li key={i} className="opinion-item">
                <div className="left-block">
                  <div className="img-zoom img-wrap">
                    <Link to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}>
                      {item?.Tail_Image?.url ? (
                        <ImageRender
                          alt={
                            item.Tail_Image?.alternativeText
                              ? item.Tail_Image?.alternativeText
                              : item.Title + " - DNG Estate Agents"
                          }
                          processedImages={processedImages}
                          image_url={item?.Tail_Image?.url}
                          node={item}
                        />
                      ) : (
                        <img
                          src={NoImage}
                          alt={item.Tail_Image?.alternativeText}
                        />
                      )}
                      {/* <img src={item.Tail_Image?.url ? item.Tail_Image?.url : NoImage} alt={item.Tail_Image?.alternativeText} /> */}
                    </Link>
                    {item.Video_Url && (
                      <div className="btn-wrapper">
                        <button type="button" onClick={playVideoHandler}>
                          <i className="icon-play"></i>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="right-block">
                  <div className="name d-none d-md-block">
                    {item?.blog_categories?.map((list, indi) => (
                      <>
                        {list.Category_Name}
                        {indi === item?.blog_categories?.length - 1 ? "" : ", "}
                      </>
                    ))}
                  </div>
                  <Link
                    to={NEWS_PAGE_URL.alias + "/" + item.URL + "/"}
                    className="detail"
                  >
                    {item.Title}
                  </Link>
                </div>
                <PlayVideo
                  isOpen={isPlay}
                  stopPlay={setIsPlay}
                  videoId={item?.Video_Url}
                  // isAutoPlay={1}
                />
              </li>
            )
          })}
        </ul>
      </div>
      }
    </Fragment>
  )
}

export default OpinionList
